import React, { useState } from 'react';
import { Field } from 'react-final-form';
import css from './FieldMultiSelect.module.css';
import { ValidationError } from '../../components';

const FieldMultiSelect = props => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [isSelected, setIsSelected] = useState(false);
  // REACT-SELECT DOESNT PLAY WELL WITH SSR
  // IF NO WINDOW DONT RENDER
  if (typeof window === 'undefined') {
    return null;
  }
  // CONDITIONALLY IMPORT SELECT MODULES
  const { default: Select, components } = require('react-select'); // eslint-disable-line global-require
  const { validate, name, options, label, className, placeholder, meta, isMulti, isRequired, disabled, defaultValue, ...rest } = props;

  const status = (isSelected && isRequired && !selectedOptions)
    ? css.error
    :
    ((selectedOptions && (selectedOptions.value !== "")))
      ? css.success
      : css.attention;
  const Option = props => (
    <div>
      <components.Option {...props}>
        <label className={css.multiselectValueLabel}>{props.label}</label>
      </components.Option>
    </div>
  );
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span className={css.multiSelectLabel}>{props.data.label}</span>
    </components.MultiValue>
  );
  const renderSelect = typeof window !== 'undefined';
  return options && renderSelect ? (
    <Field name={name} validate={validate}>
      {props => {
        const { input, meta } = props;
        // PULLING INPUT ONCHANGE OUT OF OBJECT SO THAT WE CAN IMPLEMENT OUT OWN
        const { onChange, ...rest } = input;
        const { invalid, touched, error } = meta;
        const hasError = touched && invalid && error;
        return (
          <div className={className}>
            {label ? <label className={css.gap}>{label}</label> : null}
            <div className={status}>
              <Select
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={false}
                isMulti={isMulti}
                components={[Option, MultiValue]}
                defaultValue={defaultValue && Object.keys(defaultValue).length ? defaultValue : undefined}
                options={options}
                isSearchable={isMulti}
                className={css.multiSelectBox}
                isDisabled={disabled}
                placeholder={placeholder}
                selected={selectedOptions}
                getOptionValue={value => {
                  setIsSelected(true);
                  return value.key || value.label
                }}
                {...rest}
                onBlur={() => input.onBlur(input.value)}
                onChange={item => {
                  setSelectedOptions(item);
                  input.onChange(item);
                }}
              />
              <ValidationError fieldMeta={meta} />
            </div>
          </div>
        );
      }}
    </Field>
  ) : null;
};
export default FieldMultiSelect;