import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { getMerchantListingId, getUserRole } from '../../util/helper';
import { USER_ROLE_MERCHANT } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isOriginInUse, isStockInUse } from '../../util/search';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import moment from 'moment';
import { omit } from 'lodash';
import { listingfilter } from '../../config/configListing';

const { UUID } = sdkTypes;
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/CreateCouponPage/CLEAR_UPDATED_FORM';

export const UPDATE_PROFILE_REQUEST = 'app/CreateCouponPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/CreateCouponPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/CreateCouponPage/UPDATE_PROFILE_ERROR';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/CreateCouponPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/CreateCouponPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/CreateCouponPage/CREATE_LISTING_DRAFT_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/CreateCouponPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/CreateCouponPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/CreateCouponPage/UPDATE_LISTING_ERROR';

export const UPDATE_MERCHANT_LISTING_REQUEST = 'app/CreateCouponPage/UPDATE_MERCHANT_LISTING_REQUEST';
export const UPDATE_MERCHANT_LISTING_SUCCESS = 'app/CreateCouponPage/UPDATE_MERCHANT_LISTING_SUCCESS';
export const UPDATE_MERCHANT_LISTING_ERROR = 'app/CreateCouponPage/UPDATE_MERCHANT_LISTING_ERROR';

export const SHOW_LISTING_REQUEST = 'app/CreateCouponPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/CreateCouponPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/CreateCouponPage/SHOW_LISTING_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/CreateCouponPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/CreateCouponPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/CreateCouponPage/SEARCH_LISTINGS_ERROR';

export const UPLOAD_COUPON_IMAGE_REQUEST = 'app/CreateCouponPage/UPLOAD_COUPON_IMAGE_REQUEST';
export const UPLOAD_COUPON_IMAGE_SUCCESS = 'app/CreateCouponPage/UPLOAD_COUPON_IMAGE_SUCCESS';
export const UPLOAD_COUPON_IMAGE_ERROR = 'app/CreateCouponPage/UPLOAD_COUPON_IMAGE_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/CreateCouponPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/CreateCouponPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';


export const REMOVE_COUPON_IMAGE = 'app/CreateCouponPage/REMOVE_LISTING_IMAGE';

// ================ Reducer ================ //

const initialState = {
  createListingError: null,
  createListingInProgress: false,
  listingId: null,
  submittedListingId: null,
  updateListingError: null,
  updateInProgress: false,
  updateMerchantListingError: null,
  updateMerchantListingInProgress: false,
  listing: [],
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],

  uploadedImages: {},
  uploadImageError: null,
  uploadedImagesOrder: [],
  removedImageIds: [],

  image: null,
  uploadImageError: null,
  uploadInProgress: false,
};

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }
    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingInProgress: true,
        createListingError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        // ...updateUploadedImagesState(state, payload),
        createListingInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingInProgress: false,
        createListingError: payload,
      };
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };

    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        listing: (payload.data),
        showListingError: null
      };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        // ...updateUploadedImagesState(state, payload),
        updateInProgress: false,
      };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case UPDATE_MERCHANT_LISTING_REQUEST:
      return { ...state, updateMerchantListingInProgress: true, updateMerchantListingError: null };
    case UPDATE_MERCHANT_LISTING_SUCCESS:
      return {
        ...state,
        // ...updateUploadedImagesState(state, payload),
        updateMerchantListingInProgress: false,
      };
    case UPDATE_MERCHANT_LISTING_ERROR:
      return { ...state, updateMerchantListingInProgress: false, updateMerchantListingError: payload };

    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    case UPLOAD_COUPON_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }

      const uploadedImages = {
        ...state.uploadedImages,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        uploadedImages,
        uploadedImagesOrder: state.uploadedImagesOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_COUPON_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }

      const { id, ...rest } = payload;
      const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
      return { ...state, uploadedImages };
    }
    case UPLOAD_COUPON_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
      const uploadedImages = omit(state.uploadedImages, id);
      return { ...state, uploadedImagesOrder, uploadedImages, uploadImageError: error };
    }
    case REMOVE_COUPON_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.uploadedImages[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const uploadedImages = omit(state.uploadedImages, id);
      const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);

      return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: ownListings.create
export const createListingRequest = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.update
export const updateListingRequest = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);


export const updateMerchantListingRequest = requestAction(UPDATE_MERCHANT_LISTING_REQUEST);
export const updateMerchantListingSuccess = successAction(UPDATE_MERCHANT_LISTING_SUCCESS);
export const updateMerchantListingError = errorAction(UPDATE_MERCHANT_LISTING_ERROR);

export const uploadCouponRequest = params => ({ type: UPLOAD_COUPON_IMAGE_REQUEST, payload: { params } });
export const uploadCouponSuccess = result => ({ type: UPLOAD_COUPON_IMAGE_SUCCESS, payload: result.data });
export const uploadCouponError = error => ({
  type: UPLOAD_COUPON_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: ownListings.show
export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingSuccess = response => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { data: response.data },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const removeCouponImage = imageId => ({
  type: REMOVE_COUPON_IMAGE,
  payload: { imageId },
});

// ================ Thunk ================ //

export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x',
      'variants.listing-card'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export function requestCreateCoupon(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingRequest(data));
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    return sdk.ownListings
      .create(data, queryParams)
      .then(response => {
        dispatch(createListingSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingError(storableError(e)));
      });
  };
}


export function requestUpdateListing(data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListingRequest(data));
    const { id, images, ...rest } = data;
    // const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingUpdateValues = { id, images, ...rest };
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    return sdk.ownListings
      .update(ownListingUpdateValues, queryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(showListingSuccess(response));
        return response;
      })
      .catch(e => {
        return dispatch(updateListingError(storableError(e)));
      });
  };
}


export function updateMerchatListing(params) {
  return (dispatch, getState, sdk) => {
    dispatch(updateMerchantListingRequest());
    const { currentUser } = getState().user
    const merchantlistingId = getMerchantListingId(currentUser)
    const { couponCodeArr, createdAt, numberOfCoupon } = params;
    const timestamp = moment(createdAt).unix();

    return sdk.ownListings
      .update({
        id: new UUID(merchantlistingId), publicData: {
          couponCodeArr: couponCodeArr,
          createdAtDate: timestamp,
          numberOfCoupon: numberOfCoupon
        }
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(updateMerchantListingSuccess(response));
        return response;
      })
      .catch(e => {
        return dispatch(updateMerchantListingError(storableError(e)));
      });
  };
}

export const showMerchantListing = (config) => async (dispatch, getState, sdk) => {
  try {
    const currentUser = getState().user.currentUser;
    const userRole = getUserRole(currentUser);
    const merchantListingId = getMerchantListingId(currentUser)
    var listingId = new UUID(merchantListingId);
    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;

    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Cropped variants for listing thumbnail images
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        `variants.${variantPrefix}-6x`,

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
    };

    if (userRole === USER_ROLE_MERCHANT) {
      const response = await sdk.ownListings.show(params);
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingfilter };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      return response;
    }
  } catch (error) {
    console.error(error, '&&&  &&& => error');
  }
};

export const searchListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
        pub_listingType: listingTypes.map(l => l.listingType),
        // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
        // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
      }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
          ? getExclusiveEndDate(endRaw, searchTZ)
          : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;

    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingfilter };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};
export const showListing = (listingId, config) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      'variants.default',
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  return sdk.ownListings.show(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingfilter };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(showListingSuccess(response))
      return response
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export function requestCouponImageUpload(actionPayload, listingImageConfig) {

  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const imageVariantInfo = getImageVariantInfo(listingImageConfig);
    const queryParams = {
      expand: true,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };

    dispatch(uploadCouponRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property
        return dispatch(
          uploadCouponSuccess({ data: { ...img, id, imageId: img.id, file: actionPayload.file } })
        );
      })
      .catch(e => dispatch(uploadCouponError({ id, error: storableError(e) })));
  };
}


export const loadData = (params, search, config) => (dispatch) => {

  const listingId = new UUID(params.id);
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([
    params?.id ? dispatch(showListing(listingId, config)) : null
    , dispatch(showMerchantListing(config)),
    dispatch(searchListings(
      {
        // ...minStockMaybe,
        ...rest,
        ...originMaybe,
        page,
        perPage: 100,
        include: ['author'],
        'fields.listing': [
          'title',
          'geolocation',
          'price',
          'publicData'
        ],
      },
      config
    )),
  ]);
};

export const pickRenderableImages = (
  currentListing,
  uploadedImages,
  uploadedImageIdsInOrder = [],
  removedImageIds = []
) => {
  // Images are passed to EditListingForm so that it can generate thumbnails out of them
  const currentListingImages = currentListing && currentListing.images ? currentListing.images : [];
  // Images not yet connected to the listing
  const unattachedImages = uploadedImageIdsInOrder.map(i => uploadedImages[i]);
  const allImages = currentListingImages.concat(unattachedImages);

  const pickImagesAndIds = (imgs, img) => {
    const imgId = img.imageId || img.id;
    // Pick only unique images that are not marked to be removed
    const shouldInclude = !imgs.imageIds.includes(imgId) && !removedImageIds.includes(imgId);
    if (shouldInclude) {
      imgs.imageEntities.push(img);
      imgs.imageIds.push(imgId);
    }
    return imgs;
  };

  // Return array of image entities. Something like: [{ id, imageId, type, attributes }, ...]
  return allImages.filter(ai => ai.id && unattachedImages.findIndex(ui => ui.imageId && ui.imageId.uuid == ai.id.uuid) == -1).reduce(pickImagesAndIds, { imageEntities: [], imageIds: [] }).imageEntities;
};

export const getImageQueryListing = (listingId, config) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };
}