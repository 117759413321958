import React, { useEffect, useState } from 'react';
import css from './ManageSubscriptionPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { useDispatch, useSelector } from 'react-redux';
import { ensureUser } from '../../util/data';
import moment from 'moment';
import Button from '../Button/Button';
import { getSubscriptions } from '../../containers/StripeSubscriptionPage/StripeSubscriptionPage.duck';
import { cancelStripeSubscription } from '../../util/api';
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_CANCELLED } from '../../util/types';
import { firstLetterCapital } from '../../util/helper';
// import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import NamedLink from '../NamedLink/NamedLink';
import { PROFESSIONAL_ANNUALY } from '../../util/types';
import { PROFESSIONAL_MONTHLY } from '../../util/types';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import verifyIcon from '../../assets/1024x1024-verified.gif';

const ManageSubscriptionPanel = ({ history }) => {
  const routeConfiguration = useRouteConfiguration();
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state?.user?.currentUser);
  const onlineSubscriptionData = userSubscriptions?.find(plan => plan);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const stripeCustomerId = currentUser?.attributes?.profile?.protectedData?.stripeCustomerId;

        const subscriptions = await dispatch(
          getSubscriptions({ stripeCustomerId: stripeCustomerId })
        );
        if (subscriptions?.length) {
          setUserSubscriptions(subscriptions);
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    if (currentUser?.id) {
      fetchSubscriptions();
    }
  }, [dispatch, currentUser?.id]);

  const manageSubscription = async subscriptionId => {
    try {
      const response = await cancelStripeSubscription({ subscriptionId: subscriptionId });

      if (response.status === 'canceled') {
        history.push(
          createResourceLocatorString('StripeSubscriptionPage', routeConfiguration, {}, {})
        );
      }
    } catch (err) {
      console.log(err, '&&& &&& => err');
    }
  };

  const currentSubscription =
    userSubscriptions?.length &&
    userSubscriptions?.find(
      e => e.status === SUBSCRIPTION_STATUS_ACTIVE || e.status === SUBSCRIPTION_STATUS_CANCELLED
    );

  const planName = userSubscriptions && userSubscriptions.map(st => st.plan.nickname);
  const planData = currentSubscription && currentSubscription?.plan;
  const subData = currentSubscription;
  const isPlanCancelled = currentSubscription && currentSubscription?.cancel_at_period_end;
  const cancelAt =
    currentSubscription && moment.unix(currentSubscription?.cancel_at).format('MMM D, YYYY');
  const subscriptionId = currentSubscription && currentSubscription.id;

  const getStatus = () => {
    if (currentSubscription?.status && !isPlanCancelled) {
      return firstLetterCapital(currentSubscription.status);
    } else if (isPlanCancelled) {
      return 'Cancelled';
    }
    return 'Inactive';
  };

  const subscriptionStatus = getStatus();

  const amountAsPerSubscriptionPlan =
    onlineSubscriptionData?.metadata.currency !== 'INR' && planData?.amount
      ? (planData?.amount / 100) * subData.quantity
      : 0;

  const subscriptionEndDate =
    currentUser &&
    moment.unix(currentUser.attributes?.profile?.protectedData?.freePlanData?.endDate);
  const formattedEndDate = subscriptionEndDate?.format('YYYY-MM-DD ');
  const subscriptionomplete = userSubscriptions && userSubscriptions.map(st => st.status);

  return (
    <>
      {userSubscriptions?.length ? (
        <div className={css.manageSubscriptionPanel}>
          <div className={css.headingTopBoxs}>
            <div className={css.billingInformationTitle}>
              <FormattedMessage id="ManageSubscriptionPanel.billingInformation" />
            </div>
            <div className={css.verifyBox}>
              <img src={verifyIcon} />
              <span> {subscriptionomplete && 'Subscription Completed- Welcome to Lulocal!'}</span>
            </div>
            {/* {!onlineSubscriptionData?.id ? null : (
              <NamedLink
                name="StripeSubscriptionPage"
                className={css.subscriptionPageRedirectButton}
              >
                <FormattedMessage id="ManageSubscriptionPanel.subscriptionPageRedirectButton" />
              </NamedLink>
            )} */}
          </div>
          <div className={css.planWrapper}>
            <h3 className={css.nameHeading}>
              <FormattedMessage id="ManageSubscriptionPanel.onlinePlanHeading" />
            </h3>
            <div className={css.planCard}>
              <div className={css.wrapperBox}>
                <div className={css.mobilePlanHeading}>
                  <div
                    className={
                      !onlineSubscriptionData?.id || isPlanCancelled
                        ? css.inMobileInActiveBadge
                        : css.mobileActive
                    }
                  >
                    {subscriptionStatus}
                  </div>
                </div>
                <div className={css.planHeading}>
                  <div className={css.planLeftSide}>
                    <div className={css.planHeadingBox}>
                      {planData?.nickname?.length && <h3> {planData?.nickname}</h3>}
                      <span
                        className={
                          !onlineSubscriptionData?.id || isPlanCancelled
                            ? css.inActiveBadge
                            : css.activeBadge
                        }
                      >
                        {subscriptionStatus}
                      </span>
                    </div>
                    {/* <p className={css.planDescription}>
                      {[PROFESSIONAL_MONTHLY, PROFESSIONAL_ANNUALY]?.includes(planName) ? (
                        <FormattedMessage id="ManageSubscriptionPanel.individualPlanInformation" />
                      ) : (
                        // <FormattedMessage id="ManageSubscriptionPanel.smallTeamPlanInformation" />
                      )}
                    </p> */}
                  </div>
                  <div className={css.planRightSide}>
                  </div>
                </div>
                <div className={css.teamInformation}></div>
              </div>
              <div className={css.planBody}>
                {onlineSubscriptionData?.id ? (
                  <div className={css.planBodyRight}>
                    <Button
                      onClick={() => manageSubscription(subscriptionId)}
                      className={css.subscriptionButton}
                      // disabled={true}
                    >
                      <FormattedMessage id="ManageSubscriptionPanel.cancelSubscriptionButton" />
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <h3 className={css.nameHeading}>
            <FormattedMessage id="ManageSubscriptionPanel.billingHistory" />
          </h3>
          <div className={css.tableBox}>
            <table className={css.tableStriped}>
              <thead>
                <tr className={css.subscriptionHeading}>
                  <th className={css.leftTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.planHeading" />
                  </th>
                  <th className={css.centerTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.billingDetailsHeading" />
                  </th>
                  <th className={css.rightTd}>
                    <FormattedMessage id="ManageSubscriptionPanel.amountHeading" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {userSubscriptions?.length ? (
                  userSubscriptions.map((subscription, i) => (
                    <tr key={subscription?.id} className={css.subscriptionData}>
                      <td className={css.leftTd}>
                        <div className={css.subscriptionPlanBody}>
                          {/* {planName ? planName : firstLetterCapital(subscription.plan.nickname)} */}
                          LuLo Monthly Plan
                        </div>
                      </td>
                      <td className={css.centerTd}>
                        <div className={css.subscriptionPlanBody}>
                          {moment(subscription.current_period_start * 1000).format('MMM Do YYYY')}
                        </div>
                      </td>
                      <td className={css.rightTd}>
                        <div className={css.subscriptionPlanBody}>
                          &nbsp;
                          {'$' + amountAsPerSubscriptionPlan}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={css.subscriptionData}>
                    <td className={css.leftTd}>
                      <div className={css.subscriptionPlanBody}>Free plan</div>
                    </td>
                    <td className={css.centerTd}>
                      <div className={css.subscriptionPlanBody}>
                        {formattedEndDate ? formattedEndDate : null}
                      </div>
                    </td>
                    <td className={css.rightTd}>
                      <div className={css.subscriptionPlanBody}>
                        &nbsp;
                        {'$' + 0}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <SkeletonLoader />
      )}
    </>
  );
};

export default ManageSubscriptionPanel;
