import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Modal,
  OutsideClickHandler,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import css from './TopbarDesktop.module.css';
import IconCollection from '../../../../components/IconCollection/IconCollection';
import { freePlan, getUserRole, userSubscriptions } from '../../../../util/helper';

import shoppingCart from '../../../../assets/cartIcon.png';
import { ensureCurrentUser } from '../../../../util/data';
import { useDispatch, useSelector } from 'react-redux';

const TopbarDesktop = props => {
  // Destructure the props object for easier access to the individual props
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [isUserHasSubscribed, setIsUserHasSubscribed] = useState(false);
  const [isTopbarModal, setIsTopbarModal] = useState(false);
  const cartListings = currentUser?.attributes?.profile?.publicData?.bookmarks?.map(st => st.id);
  const lengthOfCart = cartListings?.length > 0 ? cartListings?.length : null;
  const isSubscriptionActive = currentUser?.attributes?.profile?.protectedData?.userSubscriptionData
    ? true
    : false;
  const isFoodDrinkMerchant =
    currentUser?.attributes?.profile?.publicData?.availableProducts == 'Food-&-drink';

  // Set up a state variable to track whether the component has mounted or not
  const [mounted, setMounted] = useState(false);

  const currentUserListing = useSelector(state => state?.user?.userListing?.attributes?.publicData);

  const { total_listings} = currentUser?.attributes?.profile?.publicData || {};

  const { businessDescription, announcements, typeOfProducts, primaryPhotoId } =
    currentUserListing || {};
  const profileModal = businessDescription && typeOfProducts && primaryPhotoId && announcements;

  // Use the useEffect hook to set the mounted state variable to true once the component has mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // Set up state variables to track the visibility of the menu dropdowns
  const [showMenu, setShowMenu] = useState(false);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showWork, setShowWork] = useState(false);
  const [showWork2, setShowWork2] = useState(false);

  // Get the marketplace name from the appConfig object
  const marketplaceName = appConfig.marketplaceName;

  // Check if the user is authenticated on the client side
  const authenticatedOnClientSide = mounted && isAuthenticated;

  // Check if the user is authenticated or just hydrated (when the app first loads)
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  // Get the user's role using the helper function
  const userRole = currentUser && currentUser?.id && getUserRole(currentUser);

  // Set up the CSS classes for the component
  const classes = classNames(rootClassName || css.root, className);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isFreePlan = !!freePlan(ensuredCurrentUser);
  const userSubscription = !!userSubscriptions(ensuredCurrentUser)?.length;

  useEffect(() => {
    if (isFreePlan || userSubscription) {
      setIsUserHasSubscribed(true);
    }
  }, [isFreePlan, userSubscription]);

  // Create the search component
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  // Create the notification dot if there are unread notifications
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  // Create the inbox link if the user is authenticated
  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  // Create the CSS class for the current page
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  // Create the profile menu if the user is authenticated
  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          {userRole && (
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="NewListingPage">
          {userRole && !isFoodDrinkMerchant && (
            <div className={css.profileApplyButton}>
              {!isSubscriptionActive ? (
                <div
                  type="button"
                  onClick={() => setIsTopbarModal(true)}
                  className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.newListing" />
                </div>
              ) : total_listings && total_listings >= 9 ? (
                <div
                  type="button"
                  onClick={() => setIsTopbarModal(true)}
                  className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.newListing" />
                </div>
              ) : (
                <NamedLink
                  className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
                  name="NewListingPage"
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.newListing" />
                </NamedLink>
              )}
            </div>
          )}
        </MenuItem>

        <MenuItem key="ManageListingsPage">
          {userRole && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLinks" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="createCoupon">
          {userRole && (
            <div className={css.profileApplyButton}>
              {!isSubscriptionActive ? (
                <div
                  type="button"
                  onClick={() => setIsTopbarModal(true)}
                  className={classNames(css.yourListingsLink, currentPageClass('CreateCouponPage'))}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.createCoupon" />
                </div>
              ) : (
                <NamedLink
                  className={classNames(
                    css.profileSettingsLink ,
                    currentPageClass('CreateCouponPage')
                  )}
                  name="CreateCouponPage"
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.createCoupon" />
                </NamedLink>
              )}
            </div>
          )}
        </MenuItem>

        {/* <MenuItem key="CreateCouponPage">
          {userRole && (
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('CreateCouponPage'))}
              name="CreateCouponPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.createCoupon" />
            </NamedLink>
          )}
        </MenuItem> */}

        <MenuItem key="AccountSettingsPage">
          <a
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            href={userRole ? '/account/payments' : '/account/contact-details'}
          >
            <span className={css.menuItemBorder} />
            {userRole ? (
              <FormattedMessage id="TopbarDesktop.accountSettingsLinkMerchant" />
            ) : (
              <FormattedMessage id="TopbarDesktop.accountSettingsLinkCustomer" />
            )}
          </a>
        </MenuItem>

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  // Create the signup link if the user is not authenticated or just hydrated
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  // Create the merchant signup link
  const merchantSignupLink = (
    <span className={css.cardBox}>
      <NamedLink name="CartPage" className={css.signupLink}>
        <img src={shoppingCart} alt="cart" height={40} />
      </NamedLink>
      {lengthOfCart ? <span className={css.cartCount}>{lengthOfCart}</span> : null}
    </span>
  );

  // Create the login link if the user is not authenticated or just hydrated
  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="WelcomePage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.Login" />
      </span>
    </NamedLink>
  );
  const auth = useSelector(state => state.auth.isAuthenticated);
  // Render the top bar component
  return (
    <nav className={classes}>
      <div className={css.leftMenus}>
        <div>
          <div
            onClick={() => {
              setShowMenu(showMenu => !showMenu);
              setShowMenu2(false);
            }}
            className={css.dropdown}
          >
            <span className={css.createListingLink}>
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.shop" />
              </span>
            </span>
            <span>
              <IconCollection name="ARROW_DOWN" />
            </span>
            {showMenu && (
              <OutsideClickHandler
                className={css.outsideClick}
                onOutsideClick={() => [showMenu && setShowMenu(!showMenu)]}
              >
                <div className={css.dropdownContent}>
                  <NamedLink name="SearchPage" params={{ type: 'shop-by-merchants' }}>
                    Merchants
                  </NamedLink>
                  <NamedLink name="SearchPage" params={{ type: 'Products' }}>
                    Products
                  </NamedLink>
                  <NamedLink name="SearchPage" params={{ type: 'Services' }}>
                    Services
                  </NamedLink>
                  <NamedLink name="SearchPage" params={{ type: 'shop-by-food&drink' }}>
                    Food & Drinks
                  </NamedLink>
                  <NamedLink name="SearchPage" params={{ type: 'Coupon' }}>
                    Coupon
                  </NamedLink>
                </div>
              </OutsideClickHandler>
            )}
          </div>

          <div
            onClick={() => {
              setShowWork(showWork => !showWork);
              setShowWork2(false);
            }}
            className={css.dropdown}
          >
            <span className={css.createListingLink}>
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.howWorks" />
              </span>
            </span>
            <span>
              <IconCollection name="ARROW_DOWN" />
            </span>
            {showWork && (
              <OutsideClickHandler
                className={css.outsideClick}
                onOutsideClick={() => [showWork && setShowWork(!showWork)]}
              >
                <div className={css.dropdownContent}>
                  <a href="/how-it-works">What, Why, How</a>
                  <a href="/p/selling-locally">Selling Locally</a>
                  <a href="/p/sellingsimplified">Shopping Simplified</a>
                </div>
              </OutsideClickHandler>
            )}
          </div>
          <a className={css.createListingLink} href="/p/lulocal-about">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.aboutUs" />
            </span>
          </a>
        </div>
        <LinkedLogo
          className={css.logoLink}
          format="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
      </div>

      <div>
        {!profileModal ? (
          <Modal
            id="TopbarCreateListing"
            isOpen={isTopbarModal}
            onClose={() => setIsTopbarModal(false)}
            onManageDisableScrolling={() => {}}
          >
            <div className={css.termsWrapper}>
              <h4><FormattedMessage id="TopbarMobileMenu.completeProfile" /></h4>
              <NamedLink className={css.applyButton} name="ProfileSettingsPage">
              <FormattedMessage id="TopbarMobileMenu.goToProfile" />              </NamedLink>
            </div>
          </Modal>
        ) : !isSubscriptionActive ? (
          <Modal
            id="TopbarCreateListing"
            isOpen={isTopbarModal}
            onClose={() => setIsTopbarModal(false)}
            onManageDisableScrolling={() => {}}
          >
            <div className={css.termsWrapper}>
              <h4><FormattedMessage id="TopbarMobileMenu.subscribeFirst" /></h4>
              <NamedLink className={css.applyButton} name="StripeSubscriptionPage">
              <FormattedMessage id="TopbarMobileMenu.goToSubscription" />
              </NamedLink>
            </div>
          </Modal>
        ) : (
          total_listings &&
          total_listings >= 9 && (
            <Modal
              id="TopbarCreateListing"
              isOpen={isTopbarModal}
              onClose={() => setIsTopbarModal(false)}
              onManageDisableScrolling={() => {}}
            >
              <div className={css.termsWrapper}>
                <h4>              <FormattedMessage id="TopbarMobileMenu.exceededLimit" />
                </h4>
                <NamedLink className={css.applyButton} name="ManageListingsPage">
                <FormattedMessage id="TopbarMobileMenu.goToYourListing" />
                </NamedLink>
              </div>
            </Modal>
          )
        )}
      </div>

      <div>
        <div>{search}</div>
        <div
          className={classNames(
            css.rigthLinkWrapper,
            isAuthenticated ? css.authenticateRightWrapper : null
          )}
        >
          {inboxLink}
          {profileMenu}
          {merchantSignupLink}
          {loginLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
