import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess, fetchCurrentUser, fetchCurrentUserHasListings } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getMerchantListingId, getUserRole } from '../../util/helper';
import { USER_ROLE_MERCHANT } from '../../util/types';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { omit } from 'lodash';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;

// ================ Action types ================ //


// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};


const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};


export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_LISTING_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_LISTING_IMAGE_REQUEST';
export const UPLOAD_LISTING_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_LISTING_IMAGE_SUCCESS';
export const UPLOAD_LISTING_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_LISTING_IMAGE_ERROR';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/ProfileSettingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ProfileSettingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ProfileSettingsPage/FETCH_LISTINGS_ERROR';

export const UPDATE_PROFILE_LISTING_REQUEST =
  'app/ProfileSettingsPage/UPDATE_PROFILE_LISTING_REQUEST';
export const UPDATE_PROFILE_LISTING_SUCCESS =
  'app/ProfileSettingsPage/UPDATE_PROFILE_LISTING_SUCCESS';
export const UPDATE_PROFILE_LISTING_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_LISTING_ERROR';



// ================ Reducer ================ //

const initialState = {
  image: null,
  // uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  fetchListingInProgress: false,
  currentPageResultIds: [],
  fetchListingsError: null,
  updateUserListingInProgress: false,
  updateUserListingError: null,

  uploadedImages: {},
  uploadImageError: null,
  uploadedImagesOrder: [],
  removedImageIds: [],
};

const resultIds = data => data.data.map(l => l.id);


export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingInProgress: true,
        searchMapListingIds: [],
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload.response,
        fetchListingInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingInProgress: false, fetchListingsError: payload };

    case UPDATE_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        updateUserListingInProgress: true,
        updateUserListingError: null,
      };
    case UPDATE_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        updateUserListingInProgress: false,
        updateUserListingError: null,
      };
    case UPDATE_PROFILE_LISTING_ERROR:
      return {
        ...state,
        updateUserListingInProgress: false,
        updateUserListingError: payload,
      };

      case UPLOAD_LISTING_IMAGE_REQUEST: {
        // payload.params: { id: 'tempId', file }
        
        const uploadedImages = {
          ...state.uploadedImages,
          [payload.id]: { ...payload },
        };
        return {
          ...state,
          uploadedImages,
          uploadedImagesOrder: state.uploadedImagesOrder.concat([payload.id]),
          uploadImageError: null,
        };
      }
      case UPLOAD_LISTING_IMAGE_SUCCESS: {
        // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }
        
        const { id, ...rest } = payload;
        const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
        return { ...state, uploadedImages };
      }
      case UPLOAD_LISTING_IMAGE_ERROR: {
        // eslint-disable-next-line no-console
        const { id, error } = payload;
        const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
        const uploadedImages = omit(state.uploadedImages, id);
        return { ...state, uploadedImagesOrder, uploadedImages, uploadImageError: error };
      }
      case REMOVE_LISTING_IMAGE: {
        const id = payload.imageId;
   
        // Only mark the image removed if it hasn't been added to the
        // listing already
        const removedImageIds = state.uploadedImages[id]
          ? state.removedImageIds
          : state.removedImageIds.concat(id);
  
        // Always remove from the draft since it might be a new image to
        // an existing listing.
        const uploadedImages = omit(state.uploadedImages, id);
        const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
  
        return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
      } 
    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: listingImages.upload
export const uploadListingImageRequest = params => ({ type: UPLOAD_LISTING_IMAGE_REQUEST, payload: params  });
export const uploadListingImageSuccess = params => ({ type: UPLOAD_LISTING_IMAGE_SUCCESS, payload: params  });
export const uploadListingImageError = params => ({ type: UPLOAD_LISTING_IMAGE_ERROR, payload: params  }); 


// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

export const fetchListingsRequest = searchParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { searchParams },
});
export const fetchListingsSuccess = response => (
  {
  type: FETCH_LISTINGS_SUCCESS,
  payload: { response },
}
);

export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const updateMerchantListingRequest = () => ({
  type: UPDATE_PROFILE_LISTING_REQUEST,
});
export const updateMerchantListingSuccess = () => ({
  type: UPDATE_PROFILE_LISTING_SUCCESS,
});
export const updateMerchantListingError = e => ({
  type: UPDATE_PROFILE_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const fetchCurrentListing = listingId => async (dispatch,   getState, sdk) => {
  try {
    dispatch(fetchListingsRequest());
    const response = await sdk.ownListings.query({ id: listingId });
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchListingsSuccess(response));
    return response;
  } catch (error) {
    dispatch(fetchListingsError(error))
    console.error(error, "error")
  }
}

export function listingImageUpload(actionPayload, listingImageConfig) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    const imageVariantInfo = getImageVariantInfo(listingImageConfig);
    const queryParams = {
      expand: true,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };
    dispatch(uploadListingImageRequest(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file }, queryParams)
      .then(resp => {
        const img = resp.data.data;
        // Uploaded image has an existing id that refers to file
        // The UUID was created as a consequence of this upload call - it's saved to imageId property
        return dispatch(
          uploadListingImageSuccess( { ...img, id, imageId: img.id, file: actionPayload.file } )
        );
      })
      .catch(e => dispatch(uploadListingImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };
    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];
      

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

export const showMerchantListing = (config) => async (dispatch, getState, sdk) => {
  try {
    const fetchUser = await dispatch(fetchCurrentUser())
    const result =  await dispatch(fetchCurrentUserHasListings());
    const currentUser = getState().user.currentUser;
    const userRole = getUserRole(currentUser);
    const merchantListingId = getMerchantListingId(currentUser)
    var listingId = new UUID(merchantListingId);
    dispatch(fetchListingsRequest());
    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;

    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',
  
        // Cropped variants for listing thumbnail images
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        `variants.${variantPrefix}-6x`,
  
        // Social media
        'variants.facebook',
        'variants.twitter',
  
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
    };
  
    if (userRole === USER_ROLE_MERCHANT) {
      const response = await sdk.ownListings.show(params);
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };      
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
       const merchantListingId = response?.data?.data?.id?.uuid
      dispatch(fetchListingsSuccess(merchantListingId));
      return response;
    }
  } catch (error) {
    console.error(error, '&&&  &&& => error');
  }
};

export const updateMerchantProfile = (actionPayload, config, merchantListingId) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(updateMerchantListingRequest());
  const { images,...rest } = actionPayload;
    const imageProperty = typeof images !== 'undefined' ? { images: imageIds(images) } : {};
    const ownListingUpdateValues = { id: merchantListingId,...imageProperty, ...rest };
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
    const queryParams = {
      expand: true,
      include: ['author', 'images', 'currentStock'],
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };
    const response =  await sdk.ownListings.update(ownListingUpdateValues, queryParams)
    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };      
    dispatch(addMarketplaceEntities(response, sanitizeConfig));
    dispatch(updateMerchantListingSuccess());
    dispatch(showMerchantListing(config))

    return response
  } catch (error) {
    dispatch(updateMerchantListingError(error));
  }
};

export const loadData = (params,search,config) => async (dispatch, getState, sdk) => {
  try {
   dispatch(showMerchantListing(config))
  } catch (error) {
    console.error(error, '&&&  &&& => error');
  }
};

export const pickRenderableImages = (
  currentListing,
  uploadedImages,
  uploadedImageIdsInOrder = [],
  removedImageIds = []
) => {

    const currentListingImages = currentListing && currentListing.images ? currentListing.images : [];
    const unattachedImages = uploadedImageIdsInOrder.map(i => uploadedImages[i]);
    const allImages = currentListingImages.concat(unattachedImages);
  
    const pickImagesAndIds = (imgs, img) => {
      const imgId = img.imageId || img.id;
      const shouldInclude = !imgs.imageIds.includes(imgId) && !removedImageIds.includes(imgId);
      if (shouldInclude) {
        imgs.imageEntities.push(img);
        imgs.imageIds.push(imgId);
      }
      return imgs;
    };
    return allImages.filter(ai => ai.id && unattachedImages.findIndex(ui => ui.imageId && ui.imageId.uuid == ai.id.uuid) == -1).reduce(pickImagesAndIds, { imageEntities: [], imageIds: [] }).imageEntities;
  
  return null;
};

export const getImageQueryListing = (listingId,config)=>{
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };
}
