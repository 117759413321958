/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  Modal,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import { freePlan, getUserRole, userSubscriptions } from '../../../../util/helper';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;
  const [isUserHasSubscribed, setIsUserHasSubscribed] = useState(false);
  const [isTopbarModal, setIsTopbarModal] = useState(false);
  const user = ensureCurrentUser(currentUser);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isFreePlan = !!freePlan(ensuredCurrentUser);
  const userSubscription = !!userSubscriptions(ensuredCurrentUser)?.length;
  const userRole = currentUser && currentUser?.id && getUserRole(currentUser);
  const { total_listings } = currentUser?.attributes?.profile?.publicData || {};

  const cartListings = currentUser?.attributes?.profile?.publicData?.bookmarks?.map(st => st.id);
  const lengthOfCart = cartListings?.length > 0 ? cartListings?.length : null;
  const isSubscriptionActive = currentUser?.attributes?.profile?.protectedData?.userSubscriptionData
    ? true
    : false;
  const isFoodDrinkMerchant =
    currentUser?.attributes?.profile?.publicData?.availableProducts == 'Food-&-drink';

  const currentUserListing = useSelector(state => state?.user?.userListing?.attributes?.publicData);

  const businessDescription = currentUserListing?.businessDescription;
  const typeOfProducts = currentUserListing?.typeOfProducts;
  const announcements = currentUserListing?.announcements;
  const photoId = currentUserListing?.primaryPhotoId;
  const profileModal = businessDescription && typeOfProducts && photoId && announcements;


  useEffect(() => {
    if (isFreePlan || userSubscription) {
      setIsUserHasSubscribed(true);
    }
  }, [isFreePlan, userSubscription])

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="WelcomePage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink1" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('ContactUsPage'))}
          name="ContactUsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.ContactLink" />
        </NamedLink>
        {userRole && !isFoodDrinkMerchant && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink1" />
          </NamedLink>
        )}
        {userRole && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
        )}
        <span className={css.cardBox}>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CartPage'))}
            name="CartPage"
          >
            <FormattedMessage id="TopbarMobileMenu.cartitemLink" />
            {lengthOfCart ? <span className={css.cartCountMobile}>{lengthOfCart}</span> : null}
          </NamedLink>
        </span>
        {/* {userRole && (
          
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CreateCouponPage'))}
            name="CreateCouponPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.createCoupon" />
          </NamedLink>
        )} */}
        {userRole &&
          (!isSubscriptionActive ? (
            <div
              type="button"
              onClick={() => setIsTopbarModal(true)}
              className={classNames(css.navigationLink, currentPageClass('CreateCouponPage'))}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.createCoupon" />
            </div>
          ) : (
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('CreateCouponPage'))}
              name="CreateCouponPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.createCoupon" />
            </NamedLink>
          ))}

        <a
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          href={userRole ? '/account/payments' : '/account/contact-details'}
        >
          {userRole ? (
            <FormattedMessage id="TopbarDesktop.accountSettingsLinkMerchant" />
          ) : (
            <FormattedMessage id="TopbarDesktop.accountSettingsLinkCustomer" />
          )}
        </a>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        {/* {userRole && (<NamedLink className={classNames(css.createNewListingLink,!isUserHasSubscribed && css.disable)} name="NewListingPage"> */}
        {userRole && !isFoodDrinkMerchant && (
          <div className={css.profileApplyButton}>
            {!isSubscriptionActive ? (
              <div
                type="button"
                onClick={() => setIsTopbarModal(true)}
                className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.newListing" />
              </div>
            ) : total_listings && total_listings >= 9 ? (
              <div
                type="button"
                onClick={() => setIsTopbarModal(true)}
                className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.newListing" />
              </div>
            ) : (
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
                name="NewListingPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.newListing" />
              </NamedLink>
            )}
          </div>
        )}
      </div>
      <div>
        {!profileModal ? (
          <Modal
            className={css.subscribeFirstModal}
            id="TopbarCreateListing"
            isOpen={isTopbarModal}
            onClose={() => setIsTopbarModal(false)}
            onManageDisableScrolling={() => {}}
          >
            <div className={css.termsWrapper}>
              <h4>You need to complete your profile first</h4>
              <NamedLink className={css.applyButton} name="ProfileSettingsPage">
                Go to Profile page
              </NamedLink>
            </div>
          </Modal>
        ) : !isSubscriptionActive ? (
          <Modal
            className={css.subscribeFirstModal}
            id="TopbarCreateListing"
            isOpen={isTopbarModal}
            onClose={() => setIsTopbarModal(false)}
            onManageDisableScrolling={() => {}}
          >
            <div className={css.termsWrapper}>
              <h4>You need to purchase subscription first</h4>
              <NamedLink className={css.applyButton} name="StripeSubscriptionPage">
                Go to subscription page
              </NamedLink>
            </div>
          </Modal>
        ) : (
          total_listings &&
          total_listings >= 9 && (
            <Modal
              id="TopbarCreateListing"
              isOpen={isTopbarModal}
              onClose={() => setIsTopbarModal(false)}
              onManageDisableScrolling={() => {}}
            >
              <div className={css.termsWrapper}>
                <h4>You have exceeded the maximum limit for listings.</h4>
                <NamedLink className={css.applyButton} name="ManageListingsPage">
                  Go to Your Listing
                </NamedLink>
              </div>
            </Modal>
          )
        )}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
