import React, { Component, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage, Button, H6 } from '../../components';

import css from './CartPanel.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { UUID ,Money} = sdkTypes;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const CartPanelComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    bookmarks,
    handleWishlist,
    isAuthenticated,
    handleAdd,
    handleMinus,
    stockCount = '1',
    stockDetails,
    handleRemoveFromState = () => { },
    currentUser
  } = props;
  
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;

  const index = stockDetails && stockDetails.findIndex(item => item.listingId == id);
  const stock = stockDetails && stockDetails[index];

  const { title = '', price, publicData } = currentListing.attributes || {};
  const isProductListing = publicData?.category=="Products"
  
  const currentStock = currentListing && currentListing.currentStock;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  const [isHovered, setHovered] = useState('');
  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  if (isHovered && currentListing && currentListing.images && currentListing.images.length > 1) {
    firstImage = currentListing.images[1];
  }

  let cartPrice = currentUser && currentUser.attributes.profile.publicData.bookmarks.filter((st) => st.id == id);
  const finalPrice = cartPrice[0].discountedPrice !=null ? new Money (cartPrice[0].discountedPrice ,'USD') :price
  const itemTotal =
  finalPrice && stock && stock.stockCount > 1
      ? (finalPrice.amount / 100) * stock?.stockCount
      : (finalPrice.amount / 100) * 1;
  const availableQuantity =
    currentStock && currentStock.attributes && currentStock.attributes.quantity;
  const toUpperCase = str => {
    return str[0].toUpperCase() + str.substring(1);
  };

  const shownAttributes = currentUser && currentUser.attributes.profile.publicData.bookmarks.filter((st) => st.id == id);
  const finalAttributes = shownAttributes?.map((st) => st.modifiedObject)
  
  let formattedData = "";

  for (const item of finalAttributes) {
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        formattedData += `${toUpperCase(key)}: ${item[key]}, `;
      }
    }
  }
  
  // Remove the trailing comma and space
  // formattedData = formattedData.slice(0, -2);
  return (
    <div>
      <div>
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
            onMouseOver={() => setHovered('hover')}
            onMouseOut={() => setHovered('')}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
            </div>
            {formattedData.slice(0, -2)}
            <div
              className={css.favorite}
              onClick={e => {
                handleRemoveFromState(id);
                handleWishlist(id, e, null);
              }}
            >
              {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
                <span className={css.bookmark}>Remove</span>
              ) : null}
            </div>

          </div>
        </NamedLink>
      </div>

      <div className={css.productDetails}>
        <div className={css.productDetailsRight}>
          <div className={css.stock}>
            <Button type="button" onClick={handleMinus} disabled={availableQuantity <= 1}>
              -
            </Button>
            <span className={css.stockCount}>{(stock && stock.stockCount) || '1'}</span>
            <Button
              type="button"
              onClick={handleAdd}
              disabled={availableQuantity == stock?.stockCount}
            >
              +
            </Button>
          </div>
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            ${itemTotal}.00
          </div>
          {config.listing.showUnitTypeTranslations ? (
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CartPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

CartPanelComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(CartPanelComponent);
