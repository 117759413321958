import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  IconCard,
} from '../../components';

import logo from "../../assets/LULO-Tagline-VintageCopper.png"
import css from './Footer.module.css';

const renderSocialMediaLinks = (intl, config) => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const config = useConfiguration();
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl, config);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.socialLinksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                {/* <Logo format="desktop" className={css.logo} /> */}
                <img src={logo} alt="" />
              </NamedLink>

            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={classNames(css.listItem, css.listTitle)}>
                  <NamedLink name="SearchPage" params={{ type: 'Services' }} className={css.link}>
                    <FormattedMessage id="Footer.shop" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" params={{ type: 'shop-by-merchants' }} className={css.link}>
                    <FormattedMessage id="Footer.merchants" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" params={{ type: 'Products' }} className={css.link}>
                    <FormattedMessage id="Footer.products" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <a href='/s' className={css.link}>
                    <FormattedMessage id="Footer.services" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href='/s' className={css.link}>
                    <FormattedMessage id="Footer.food" />
                  </a>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.currentPromotions" />
                  </NamedLink>
                </li> */}
                {/* <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'about' }} className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.toSearchPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li> */}
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={classNames(css.listItem, css.listTitle)}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.howWorks" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <a href='/how-it-works' className={css.link}>
                    <FormattedMessage id="Footer.whatwhy" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href='/p/selling-locally' className={css.link}>
                    <FormattedMessage id="Footer.selling" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href='/p/sellingsimplified' className={css.link}>
                    <FormattedMessage id="Footer.simplified" />
                  </a>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink
                    name="LandingPage"
                    to={{
                      search:
                        '?address=Rukatunturi%2C%20Kuusamo%2C%20Finland&bounds=66.1699707%2C29.167735499999935%2C66.1609529%2C29.13572069999998&origin=66.1654622%2C29.151728100000014',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link5" />
                  </NamedLink>
                </li> */}
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={classNames(css.listItem, css.listTitle)}>
                  {/* <FormattedMessage id="Footer.legals" /> */}
                  <FormattedMessage id="Footer.Faqs" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="MerchantFaq" className={css.link}>
                    <FormattedMessage id="Footer.merchantsFAQs" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ShopperFaq" className={css.link}>
                    <FormattedMessage id="Footer.shopperFAQs" />
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.PrivacyPolicy" />
                  </NamedLink>
                </li>
              <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.termsUse" />
                  </NamedLink>
                </li> */}
                {/* <li className={css.listItem}>
                  <NamedLink
                    name="LandingPage"
                    to={{
                      search:
                        '?address=Ahvenanmaan%20saaristo%2C%20Åland%20Islands&bounds=60.87664400000001%2C21.34532850000005%2C59.45425059999999%2C19.94061499999998&origin=59.8994405%2C20.593996100000027',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link10" />
                  </NamedLink>
                </li> */}
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={classNames(css.listItem, css.listTitle)}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.connect" />
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.info" />
                  </NamedLink>
                </li> */}
                <div className={css.socialLogo}>
                  <IconCard brand="facebook" />
                  <IconCard brand="insta" />
                  <IconCard brand="linkedin" />
                  <IconCard brand="tiktok" />
                  <a href='/contact-us'>
                    <IconCard brand="mail" />
                  </a>

                </div>
              </ul>
            </div>

          </div>
          <div className={css.organizationInfo}>

            <div className={css.extraLinks}>
              {socialMediaLinks.length > 0 ? (
                <div className={css.socialLinks}>{socialMediaLinks}</div>
              ) : null}
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="LandingPage" className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>|</li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
