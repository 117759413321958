import React from 'react';

import css from './WelcomePage.module.css';
import { NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';

import logo from '../../assets/LULO-Primary-VintageCopper-229x80.png';
import handMike from '../../assets/handMike.png';
import IconCollection from '../../components/IconCollection/IconCollection';
import { useConfiguration } from '../../context/configurationContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { USER_ROLE_MERCHANT, USER_ROLE_SHOPPER } from '../../util/types';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';

const WelcomePage = () => {
  const config = useConfiguration();
  const userTypes = (config && config?.user?.userTypes) || {};
  const filtertedType = userTypes.map(user => {
    return { label: user.label, type: user.userType };
  });
  const routes = useRouteConfiguration();

  const history = useHistory();
  const clickHandler = userType => {
    if (userType == USER_ROLE_MERCHANT) {
      history.push('/p/rate-plans');
    }
    if (userType == USER_ROLE_SHOPPER) {
      history.push(createResourceLocatorString('SignupPage', routes, {}));
    }
  };

  return (
    <div className={css.welcomeWrapper}>
      <NamedLink name="LandingPage" className={css.closeIcon}>
        <IconCollection name="CLOSE_ICON" />
      </NamedLink>
      <div className={css.welcomeContents}>
        <NamedLink name="LandingPage">
          <img className={css.logoImg} src={logo} alt="" />
        </NamedLink>
        <img className={css.handMike} src={handMike} alt="" />
        <h1>
          <FormattedMessage id="WelcomPage.ready" />
        </h1>
        <p className={css.firstThings}>
          <FormattedMessage id="WelcomPage.firstThing" />
        </p>
        <div className={css.linkContainer}>
          {filtertedType.map(item => {
            const itemType = item.type;
            return (
              <div>
                {
                  <div
                    className={itemType == USER_ROLE_MERCHANT ? css.merchantLink : css.shopperLink}
                    key={itemType}
                  >
                    <span
                      className={itemType == USER_ROLE_MERCHANT ? css.login : css.signup}
                      onClick={() => clickHandler(item.type)}
                    >
                      {itemType == USER_ROLE_MERCHANT ? (
                        <FormattedMessage id="TopbarDesktop.iMerchant" />
                      ) : (
                        <FormattedMessage id="TopbarDesktop.iShopper" />
                      )}
                    </span>
                  </div>
                }
              </div>
            );
          })}
          {/* <NamedLink name="MerchantSignupPage" className={css.merchantLink}>
              <span >
                <FormattedMessage id="TopbarDesktop.iMerchant" />
              </span>
            </NamedLink> */}
          {/* <a href="/p/rate-plans" className={css.merchantLink}>
            <span className={css.login}>
              <FormattedMessage id="TopbarDesktop.iMerchant" />
            </span>
          </a>
          <NamedLink name="SignupPage" className={css.shopperLink}>
            <span className={css.signup}>
              <FormattedMessage id="TopbarDesktop.iShopper" />
            </span>
          </NamedLink>*/}
        </div>
        <div className={css.alreadyAccount}>
          <h6>
            {' '}
            <FormattedMessage id="WelcomPage.alreadyHaveAnAccount" />
          </h6>
          <NamedLink name="LoginPage" className={css.loginBtn}>
            <span>
              <FormattedMessage id="TopbarDesktop.signIn" />
            </span>
          </NamedLink>
        </div>
        <p>
          <FormattedMessage id="WelcomPage.termsNConditions" />
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
