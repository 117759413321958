import React, { useState } from 'react';

import css from './ShopperFAQ.module.css';
import { Button, Footer, LayoutSingleColumn, NamedLink, Page, Topbar } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import Collapsible from 'react-collapsible';
import faqImg from '../../assets/faq.png';
import IconCollection from '../../components/IconCollection/IconCollection';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import faqImage from '../../../src/assets/RaisedHands.png'

const ShopperFAQPage = (props) => {
  const topbar = <TopbarContainer />;
  const [activeTab, setActiveTab] = useState(1);
  const { scrollingDisabled } = props;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={topbar} footer={<Footer />}>
        <div className={css.faqWrapper}>
          <div className={css.titleSection}>
            <div className={css.titleWrapper}>
              {/* <h1><FormattedMessage id="FaqPage.faqTitle"/></h1>
            <h6><FormattedMessage id="FaqPage.byShopper"/></h6> */}
              <h1>Shopper FAQs</h1>
              <h6>You’ve got questions —</h6>
              <h6>We’ve got answers</h6>
            </div>
            <div className={css.imageSection}>
              <img src={faqImage} />
            </div>
          </div>

          <div className={css.tabWrapper}>
            <div>
              <div className={css.topic}>Topic:</div>
              <a className={activeTab == 1 && css.activeTab} onClick={() => setActiveTab(1)} href='#generalQuestion'>General Questions</a>
              <a className={activeTab == 2 && css.activeTab} onClick={() => setActiveTab(1)} href='#account'>My Account</a>
              <a className={activeTab == 3 && css.activeTab} onClick={() => setActiveTab(1)} href='#Purchases'>Purchases</a>
              <a className={activeTab == 4 && css.activeTab} onClick={() => setActiveTab(3)} href='#Shipping'>Shipping & Returns</a>
            </div>
          </div>
          <div id="generalQuestion" className={css.myAccountWrapper}>
            <h3>General Questions</h3>
            <Collapsible
              trigger={
                <>
                  <h4>What does the name LuLocal stand for?</h4>
                </>
              }
            >
              <p>
                LuLocal is short for “Love You Local!”
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Do I need to sign up for an account to browse LuLocal?</h4>
                </>
              }
            >
              <p>
                You can browse everything the marketplace has to offer without an account. Once you decide to make a
                purchase, you will need signup which only takes a few minutes.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Will I be charged for creating a LuLocal shopper account?</h4>
                </>
              }
            >
              <p>
                No, it’s free, free, free. We love introducing shoppers to local merchants that are excited to meet you!
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>I forgot my username/password for my account. How do I
                    log in?</h4>
                </>
              }
            >
              <p>
                Click Login/SignUp on LuLocal.com. Then click “Sign In” and then “Reset Password”. Follow instructions from
                there.
              </p>
            </Collapsible>
          </div>
          <div id="account" className={css.myAccountWrapper}>
            <h3>My Account</h3>
            <Collapsible
              trigger={
                <>
                  <h4>Who should I contact if I have a purchase related issue with
                    a merchant.</h4>
                </>
              }
            >
              <p>
                LuLocal manages the marketplace that connects shoppers and buyers, but transactions are between the two
                parties. LuLocal does not receive or hold transaction funds. We do have policies in place with our merchants, so if
                you believe that a merchant is not operating in an ethical manner, please email us at support@lulocal.com
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>My order arrived damaged. What should I do?</h4>
                </>
              }
            >
              <p>
                Please contact the merchant. LuLocal manages the marketplace that connects shoppers and buyers, but
                transactions and shipments are managed directly by the merchants.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I log in through a social account?</h4>
                </>
              }
            >
              <p>
                Yes, you will be given the option to use a social login to create an account.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Where can I find your Terms and Conditions and Privacy
                    Policy?</h4>
                </>
              }
            >
              <p>
                The links for both are in the footer of all our webpages.
              </p>
            </Collapsible>
          </div>
          <div id="Purchases" className={css.myAccountWrapper}>
            <h3>Purchases</h3>
            <Collapsible
              trigger={
                <>
                  <h4>Can I use a merchant gift card for my LuLocal purchases?</h4>
                </>
              }
            >
              <p>
                LuLocal is unable to accept dedicated merchant gift cards. Please use merchant branded gift cards directly with
                the merchant.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Can I use the merchant coupons promoted on LuLocal at
                    the merchant’s place of business?</h4>
                </>
              }
            >
              <p>
                The merchant notes on the coupon where the coupon can be redeemed. In some cases, it may only be
                redeemable in the LuLocal marketplace, or only redeemable at the merchant’s place of business. It many cases,
                they will be redeemable in both places, so please see coupon for redemption options.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>What kind of promotions does LuLocal offer?</h4>
                </>
              }
            >
              <p>
                Sales and coupons are determined by the participating merchants. Once you visit a merchant’s business page,
                you will see all their listings, sales and coupons. You can also click the “coupons” link on the landing page to see
                all merchant coupons in one place.
              </p>
            </Collapsible>
          </div>
          <div id="Shipping" className={css.myAccountWrapper}>
            <h3>Shipping & Returns</h3>
            <Collapsible
              trigger={
                <>
                  <h4>How do I return an item I purchased through LuLocal?</h4>
                </>
              }
            >
              <p>
              LuLocal manages the marketplace that connects shoppers and buyers, but transactions and returns are between
              the two parties. Please contact the merchant for return options.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <>
                  <h4>Does LuLocal ship orders outside of the U.S.?</h4>
                </>
              }
            >
              <p>
              Merchants ship their own products, and they determine where they ship and the associated cost. If you want to
order a product and have it shipped to a location outside the US, please contact the merchant for available
options.
              </p>
            </Collapsible>
          </div>
          <div className={css.localShoppingWrapper}>
            <h1>Didn’t find the answer you need?</h1>
            <a href='/contact-us' className={css.link}>
              <Button>Contact Us</Button>
            </a>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};
const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};
const ShopperFAQ = compose(connect(mapStateToProps))(ShopperFAQPage);
export default ShopperFAQ;
