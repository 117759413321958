import React, { useEffect, useRef, useState } from 'react';
import SignupForm from '../AuthenticationPage/SignupForm/SignupForm';
import TermsAndConditions from '../AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { Modal, NamedRedirect, Page, Topbar } from '../../components';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TermsOfServiceContent } from '../TermsOfServicePage/TermsOfServicePage';
import { PrivacyPolicyContent } from '../PrivacyPolicyPage/PrivacyPolicyPage';
import { authenticationInProgress, signup, signupError } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { camelize } from '../../util/string';
import {
  PRIVACY_POLICY_ASSET_NAME,
  TOS_ASSET_NAME,
} from '../AuthenticationPage/AuthenticationPage.duck';
import { USER_ROLE_MERCHANT, USER_ROLE_SHOPPER } from '../../util/types';
import EmailVerificationInfo from '../AuthenticationPage/EmailVerificationInfo';
import { ensureCurrentUser } from '../../util/data';
import { func } from 'prop-types';
import { isSignupEmailTakenError } from '../../util/errors';
import css from '../AuthenticationPage/AuthenticationPage.module.css';
import { useConfiguration } from '../../context/configurationContext';


function MerchantSignUpPagecomponent(props) {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    submitSignup,
    isAuthenticated,
    signupInProgress,
    currentUser,
    signupError,
    onResendVerificationEmail,
    setSignUpError,
  } = props;


  useEffect(() => {
    setSignUpError(null);
  }, [])

  const signupErrorMessage = (
    <div className={css.error} >
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = errorMessage(signupError, signupErrorMessage);

  const user = ensureCurrentUser(currentUser);
  const verify = user?.attributes?.emailVerified;

  if (isAuthenticated && verify) {
    return <NamedRedirect name="LandingPage" />;
  }

  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const handleSubmitSignup = async values => {
    const {
      fname,
      lname,
      businessName,
      businessDescription,
      directbuy,
      haveStoreHome,
      typeOfProducts,
      businessAddress,
      webAddress,
      ...rest
    } = values;
    const params = {
      firstName: fname?.trim(),
      lastName: lname?.trim(),
      businessName,
      businessDescription,
      directbuy,
      haveStoreHome,
      typeOfProducts,
      businessAddress,
      webAddress,
      role: USER_ROLE_MERCHANT,
      userType: USER_ROLE_MERCHANT,
      ...rest,
    };
    await submitSignup(params);
  };

  const authInProgress = false;
  const config= useConfiguration()
  const userType = USER_ROLE_MERCHANT
  const { userFields, userTypes = [] } = config.user;
  const preselectedUserType = userTypes.find(conf => conf.userType === userType)?.userType || null;


  const tosModalContentRef = useRef(null);

  const scrollToTop = () => {
    if (tosModalContentRef.current) {
      tosModalContentRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (tosModalContentRef.current && tosModalOpen) {
      scrollToTop();
    }
  }, [tosModalOpen]);

  return (
    <Page scrollingDisabled={scrollingDisabled}>

      <SignupForm
        onSubmit={handleSubmitSignup}
        signupInProgress={signupInProgress}
        inProgress={authInProgress}
        errorMessage={loginOrSignupError}
        termsAndConditions={
          <TermsAndConditions
            onOpenTermsOfService={() => setTosModalOpen(true)}
            onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
            intl={intl}
          />
        }
        pageName={"MerchantSignUpPage"}
        preselectedUserType={preselectedUserType}
        userTypes={userTypes}
        userFields={userFields}
      />
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div
          ref={tosModalContentRef}
          className={css.modalScroll}
        >
          {/* Example: Displaying content */}
          <TermsOfServiceContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div
          ref={tosModalContentRef}
          className={css.modalScroll}
        >
          <PrivacyPolicyContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <div>
        <Modal
          ReadyTo={true}
          id="AuthenticationPage.privacyPolicy"
          isOpen={isAuthenticated && !verify}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <EmailVerificationInfo
            name={user.attributes.profile.firstName}
            email={<span>{user.attributes.email}</span>}
            onResendVerificationEmail={onResendVerificationEmail}
          />
        </Modal>

      </div>
    </Page>
  );
}

MerchantSignUpPagecomponent.propTypes = {
  onResendVerificationEmail: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError, signupInProgress } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    signupInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  submitSignup: params => dispatch(signup(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  setSignUpError: () => dispatch(signupError(false)),
});

const MerchantSignUpPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MerchantSignUpPagecomponent);

export default MerchantSignUpPage;